/* @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Bevan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

body {
  margin: 0;
  /* font-family: 'Caveat', cursive; */
  font-family: 'Shadows Into Light', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./images/assets/bg2.jpg);
  background-size: 300px;
}

body::before{
  content: '';
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
  position: absolute;
  top:0;
}
 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
