:root {
  --gutter: 30px;
  --side-page-spacing: 5%;
}

@media (max-width: 700px) {
  :root {
    --side-page-spacing: 0;
  }
}

.App {
  text-align: center;
  font-size: 16px;
  padding-left: var(--side-page-spacing);
  padding-right: var(--side-page-spacing);
}

.header {
  font-size: 80px;
  font-family: 'Bevan', cursive;
  color: #e5cb83;
  padding:0;
  margin: 0;
  line-height: 1;
  padding:10px 50px;
  transform: rotate(-1deg);
  position: relative;
  letter-spacing: -2px;
  margin: 0;
}


@keyframes twerk {
  0% {transform: rotate(-8deg);}
  50% {transform: rotate(8deg);}
  100% {transform: rotate(-8deg);}
}


.joeface-left, .joeface-right {
  background-image: url('./images/assets/joseph-face.png');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top:20px;
  width: 80px;
  height:120px;
  margin: 0 -40px;
  animation-name: twerk;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}


.joeface-left{left:-20px}
.joeface-right{left:30px}

.header-subtitle{
  color: #e5cb83;
  background-color: #0f3c4c;
  transform: rotate(2deg);
  font-size: 26px;
  top: -10px;
  padding: 2px 20px;
  display: inline;
  position: relative;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  line-height: 2.3;
}

.header-container{
  margin-bottom: 20px;
}

.card, .photo-card, .photo-message-card {
  margin-bottom: var(--gutter);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  min-height: 100px;
  text-align: left;
}

.card{
  padding: 20px;
  /* background-color:white; */
  background-image: url(./images/assets/linedpaper.png);
  border-left: 8px solid #eee6d0;
  background-position-y: 20px;
  background-size: 590px;
}

.photo-card, .photo-message-card {
  background-color: white;
  border: 10px solid white;
}

.photo-card img, .photo-message-card img {
  display:block;
}

.photo-caption{
  display: inline-block;
  margin-top: 6px;
  line-height: 1.3;
  color: black;
  text-transform: capitalize;
}

.card-display {
  padding: 30px;
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.card-display__column {
  padding-left: var(--gutter); /* gutter size */
  background-clip: padding-box;
}

.card-content {
  font-size: 24px;
  line-height: 1.5;
  color: rgb(19, 42, 131);
  position:relative;
  padding: 0 10px;
  white-space: pre-wrap;
}


.card-author {
  font-size: 24px;
  color: #e2aa11;
  width: 100%;
  text-align: right;
  position: relative;
  top: 8px;
}

.card-index {
  display: none;
  z-index: 1;
}