@import url(https://fonts.googleapis.com/css2?family=Bevan&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap'); */

body {
  margin: 0;
  /* font-family: 'Caveat', cursive; */
  font-family: 'Shadows Into Light', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/bg2.4c2e4354.jpg);
  background-size: 300px;
}

body::before{
  content: '';
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
  position: absolute;
  top:0;
}
 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --gutter: 30px;
  --side-page-spacing: 5%;
}

@media (max-width: 700px) {
  :root {
    --side-page-spacing: 0;
  }
}

.App {
  text-align: center;
  font-size: 16px;
  padding-left: 5%;
  padding-left: var(--side-page-spacing);
  padding-right: 5%;
  padding-right: var(--side-page-spacing);
}

.header {
  font-size: 80px;
  font-family: 'Bevan', cursive;
  color: #e5cb83;
  padding:0;
  margin: 0;
  line-height: 1;
  padding:10px 50px;
  transform: rotate(-1deg);
  position: relative;
  letter-spacing: -2px;
  margin: 0;
}


@-webkit-keyframes twerk {
  0% {transform: rotate(-8deg);}
  50% {transform: rotate(8deg);}
  100% {transform: rotate(-8deg);}
}


@keyframes twerk {
  0% {transform: rotate(-8deg);}
  50% {transform: rotate(8deg);}
  100% {transform: rotate(-8deg);}
}


.joeface-left, .joeface-right {
  background-image: url(/static/media/joseph-face.5f4ca5a5.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top:20px;
  width: 80px;
  height:120px;
  margin: 0 -40px;
  -webkit-animation-name: twerk;
          animation-name: twerk;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-direction: reverse;
}


.joeface-left{left:-20px}
.joeface-right{left:30px}

.header-subtitle{
  color: #e5cb83;
  background-color: #0f3c4c;
  transform: rotate(2deg);
  font-size: 26px;
  top: -10px;
  padding: 2px 20px;
  display: inline;
  position: relative;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  line-height: 2.3;
}

.header-container{
  margin-bottom: 20px;
}

.card, .photo-card, .photo-message-card {
  margin-bottom: 30px;
  margin-bottom: var(--gutter);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  min-height: 100px;
  text-align: left;
}

.card{
  padding: 20px;
  /* background-color:white; */
  background-image: url(/static/media/linedpaper.88b92587.png);
  border-left: 8px solid #eee6d0;
  background-position-y: 20px;
  background-size: 590px;
}

.photo-card, .photo-message-card {
  background-color: white;
  border: 10px solid white;
}

.photo-card img, .photo-message-card img {
  display:block;
}

.photo-caption{
  display: inline-block;
  margin-top: 6px;
  line-height: 1.3;
  color: black;
  text-transform: capitalize;
}

.card-display {
  padding: 30px; /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.card-display__column {
  padding-left: 30px;
  padding-left: var(--gutter); /* gutter size */
  background-clip: padding-box;
}

.card-content {
  font-size: 24px;
  line-height: 1.5;
  color: rgb(19, 42, 131);
  position:relative;
  padding: 0 10px;
  white-space: pre-wrap;
}


.card-author {
  font-size: 24px;
  color: #e2aa11;
  width: 100%;
  text-align: right;
  position: relative;
  top: 8px;
}

.card-index {
  display: none;
  z-index: 1;
}
.photo-message {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  transition: all 0.4s ease-in-out 0s;
}

.photo-message-card {
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.photo-message-card:hover .photo-message,
.photo-message-card.active .photo-message {
  opacity: 1;
}

.photo-message-card,
.photo-message-card * {
  box-sizing: border-box;
}

.photo-message-card img {
  max-width: 100%;
}
.photo-message-card .photo-message-img {
  display: block;
}

.photo-message-card .photo-message-card-text {
  text-align: center;
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

